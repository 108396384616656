<template>
  <div class="process-modal-container">
    <sweet-modal ref="modal"  @close="closeModal"
                 overlay-theme="dark"
                 :hideCloseButton="true"
                 :enable-mobile-fullscreen="true">
      <div class="modal-container">
        <div class="modal-header">
          <div class="body2-medium">{{ headTitle }}</div>
          <img src="/static/icon/fi_close_outline.svg" class="svg-sub3 unselect" @click="$refs.modal.close()">
        </div>
        <div class="flex-center" style="height:calc(100% - 56px)">
          <div class="modal-content">
            <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 16H12.01" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 8V12" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>


            <div class="subtitle4 main" style="margin-top: 70px">{{ title }}</div>
            <div class="body4 sub3 margin-top-8" style="white-space: pre-line">{{ content }}</div>

            <div class="grid-buttons">
              <button class="button is-primary" @click="clickLink(buttonLeft.link)">{{ buttonLeft.text }}</button>
              <button v-if="visibleRight"
                class="button is-gray" @click="clickLink(buttonRight.link)">{{ buttonRight.text }}</button>
            </div>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import {SweetModal} from 'sweet-modal-vue'

  export default {
    name: "LpProcessModal",
    components: {
      SweetModal
    },
    mounted() {
      this.$nextTick(()=>{
        this.$refs.modal.open();
      });
    },
    props: {
      req: {
        type: Boolean
      },
      headTitle: {
        type: String
      },
      title: {
        type: String
      },
      content: {
        type: String
      },
      buttonLeft: {
        type: Object,
        default: function() {
          return {
            'text': '취소',
            'link': 'close'
          }
        }
      },
      buttonRight: {
        type: Object,
        default: function() {
          return {
            'text': '확인',
            'link': 'close'
          }
        }
      },
      visibleRight: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {}
    },
    methods: {
      clickLink(link) {
        this.$refs.modal.close();
        setTimeout(()=>{
          if(link === 'close') {
            this.$emit('close');
          }
          else if(this.req) {
            if(link.indexOf('http')===0) {
              window.open(link, '_blank');
            } else {
              this.$router.push(link);
            }
          }
          else {
            window.parent.opener.postMessage({close: true, redirectUrl: link}, '*');
          }
        },400);
      },
      closeModal() {
        setTimeout(()=>{
          this.$emit('close');
        }, 500);
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .modal-header
    background-color $gray2
    padding 16px 24px
    display flex
    justify-content space-between
    align-items center
  .modal-container
    width 500px
    height 689px
    max-height 90vh

  .modal-content
    text-align center
    padding 0 40px 100px 40px

  .grid-buttons
    margin-top 60px
    display flex
    gap 16px
    button
      flex 1
      width 100%
      height 40px
</style>
<style lang="stylus">
  .process-modal-container
    .sweet-modal.is-alert .sweet-content
      padding 0 !important
      text-align left

    .sweet-modal
      min-width 500px
      max-width 500px
      border-radius 8px
      max-height 90vh
      height 689px
      background-color white
</style>
